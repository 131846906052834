import { Injectable, NgZone, Optional } from '@angular/core';
import {
    Observable,
    Subject,
    Subscription,
    from,
    fromEvent,
    interval,
    merge,
    race,
    timer
} from 'rxjs';
import {
    bufferTime,
    filter,
    finalize,
    map,
    switchMap,
    takeUntil,
    tap
} from 'rxjs/operators';
import { INACTIVE_MODAL_ID } from 'src/app/app.constants';
import { InactiveUserModalComponent } from './inactive-user-modal/inactive-user-modal.component';
import { InactiveUserConfig } from './inactive-user.module';
import {MatDialog} from "@angular/material/dialog";

@Injectable({
    providedIn: 'root',
})
export class InactiveUserService {
    /**
     * Events that can interrupts user's inactivity timer.
     */
    private activityEvents$!: Observable<any>;

    private idleDetected$ = new Subject<boolean>();
    private timeout$ = new Subject<boolean>();
    private idle$!: Observable<any>;
    private timer$!: Observable<any>;
    /**
     * Idle buffer wait time milliseconds to collect user action
     * Default equals to 1 Sec.
     */
    private idleSensitivityMillisec = 1000;
    /**
     * Timeout value in seconds.
     * Default equals to 5 minutes.
     */
    private timeout = 300;
    /**
     * Ping to check timeout, value in milliseconds.
     * Default equals to 30 seconds.
     */
    protected pingMillisec = 30 * 1000;
    /**
     * Timeout status.
     */
    private isTimeout = false;
    private isIdleDetected = false;
    private startDate = new Date();
    private idleSubscription!: Subscription;

    constructor(
        @Optional() config: InactiveUserConfig,
        private _ngZone: NgZone,
        private dialog: MatDialog
    ) {
        if (config) {
            this.setConfig(config);
        }
    }

    startInactiveUserSchedule() {
        this.startWatching();
        this.startTimer();
        this.onTimeout()
            .pipe(
                tap(() => this.dialog.closeAll()),
                switchMap(() => {
                    return this.dialog
                        .open(InactiveUserModalComponent, {
                            id: INACTIVE_MODAL_ID,
                            panelClass: 'curved-modal',
                        })
                        .afterClosed();
                })
            )
            .subscribe((stopSchedule) => {
                if (stopSchedule) {
                    this.stopInactiveUserSchedule();
                }
            });
    }

    stopInactiveUserSchedule() {
        if (this.idleSubscription) {
            this.idleSubscription.unsubscribe();
        }
    }

    /**
     * Start watching for user idle and setup timer.
     */
    private startWatching() {
        if (!this.activityEvents$) {
            this.activityEvents$ = race(
                fromEvent(window, 'mousemove'),
                fromEvent(window, 'scroll'),
                fromEvent(window, 'resize'),
                fromEvent(document, 'keydown')
            );
        }

        this.idle$ = from(this.activityEvents$).pipe(
            tap(() => this.resetTimeout())
        );

        if (this.idleSubscription) {
            this.idleSubscription.unsubscribe();
        }

        // If any of user events is not active for idle-seconds when start timer.
        this.idleSubscription = this.idle$
            .pipe(
                bufferTime(this.idleSensitivityMillisec), // Starting point of detecting of user's inactivity
                filter(
                    (arr) =>
                        !arr.length && !this.isIdleDetected && !this.isTimeout
                ),
                tap(() => {
                    this.isIdleDetected = true;
                    this.idleDetected$.next(true);
                }),
                switchMap(() =>
                    this._ngZone.runOutsideAngular(() =>
                        interval(1000).pipe(
                            takeUntil(merge(this.activityEvents$, this.timer$)),
                            finalize(() => {
                                this.isIdleDetected = false;
                                this.idleDetected$.next(false);
                            })
                        )
                    )
                )
            )
            .subscribe();
    }

    private resetTimeout() {
        this.isTimeout = false;
        this.startDate = new Date();
    }

    /**
     * Return observable for timeout is fired.
     */
    private onTimeout(): Observable<boolean> {
        return this.timeout$.pipe(
            filter((timeout) => !!timeout),
            tap(() => (this.isTimeout = true)),
            map(() => true)
        );
    }

    private setConfig(config: InactiveUserConfig) {
        if (config.idleSensitivity) {
            this.idleSensitivityMillisec = config.idleSensitivity * 1000;
        }
        if (config.ping) {
            this.pingMillisec = config.ping * 1000;
        }
        if (config.timeout) {
            this.timeout = config.timeout;
        }
    }

    /**
     * Setup timer.
     *
     * Counts every seconds and return n+1 and fire timeout for last count.
     */
    private startTimer() {
        this.startDate = new Date();
        this._ngZone.runOutsideAngular(() => {
            this.timer$ = timer(this.pingMillisec).pipe(
                map(() =>
                    Math.round(
                        (new Date().valueOf() - this.startDate.valueOf()) / 1000
                    )
                ), //   convert elapsed count to seconds
                tap((elapsed) => {
                    if (elapsed >= this.timeout) {
                        this.isTimeout = true;
                        this.timeout$.next(true);
                    }
                })
            );
        });
    }
}
