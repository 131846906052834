import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { BaseModalModule } from '../base-modal/base-modal.module';
import { InactiveUserModalComponent } from './inactive-user-modal/inactive-user-modal.component';

export class InactiveUserConfig {
    /**
     * Timeout value in seconds.
     */
    timeout?: number;
    /**
     * Ping to check timeout
     */
    ping?: number;
    /**
     * IdleSensitivity time that activity must remain below the idle detection threshold before
     * idle buffer timer count user's activity actions, in seconds.
     */
    idleSensitivity?: number;
}

/**
 * Code adapted from the angular-user-idle library intended for version 13+ of angular;
 *
 * - https://github.com/rednez/angular-user-idle
 */
@NgModule({
    imports: [CommonModule, BaseModalModule, TranslateModule],
    declarations: [InactiveUserModalComponent],
})
export class InactiveUserModule {
    static forRoot(
        config: InactiveUserConfig
    ): ModuleWithProviders<InactiveUserModule> {
        return {
            ngModule: InactiveUserModule,
            providers: [{ provide: InactiveUserConfig, useValue: config }],
        };
    }
}
