<div class="auth-full container-fluid">
    <div class="password-nav-actions">
        <a class="back-to-login" [routerLink]="['/authentication']">
            <i class="material-icons">keyboard_backspace</i>
            {{ 'authentication.back-to-login' | translate }}
        </a>
        <app-lang-switcher></app-lang-switcher>
    </div>

    <div class="auth absolute-center">
        <header class="header mb-6">
            <app-animated-logotipo></app-animated-logotipo>

            <h2 class="change-password-title mt-4 mb-4" translate>authentication.change-password.title</h2>
            <h3 class="change-password-description" translate>authentication.change-password.description</h3>
        </header>

        <form class="form-login" [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="form-group mb-2">
                <div class="d-inline-flex password-group relative">
                    <img class="input-icon" src="../../../../../assets/images/material-icon-lock.svg"/>
                    <input
                        maxlength="150"
                        aria-label="Password"
                        class="form-control col-12 input-password"
                        [type]="showPassword ? 'text' : 'password'"
                        placeholder="{{ 'authentication.new-password' | translate }}"
                        formControlName="password"
                        [ngClass]="hasError(form.controls.password)"
                        #password>

                    <div class="input-group-append copy">
                        <span class="input-group-text">
                            <i class="material-icons" (click)="toggleShowPassword()">
                                {{ showPassword ? 'visibility' : 'visibility_off' }}
                            </i>
                         </span>
                    </div>
                </div>
            </div>
            <app-input-error field="password" [formGroup]="form"></app-input-error>

            <mat-password-strength #passwordComponent [password]="password.value"></mat-password-strength>
            <mat-password-strength-info [lowerCaseCriteriaMsg]="'users.criterias.lowercase' | translate"
                [upperCaseCriteriaMsg]="'users.criterias.uppercase' | translate"
                [digitsCriteriaMsg]="'users.criterias.digits' | translate"
                [specialCharsCriteriaMsg]="'users.criterias.special-chars' | translate"
                [minCharsCriteriaMsg]="'users.criterias.min-chars' | translate"
                [passwordComponent]="passwordComponent"></mat-password-strength-info>

            <div class="form-group mt-2 mb-1">
                <div class="d-inline-flex password-group relative">
                    <img *ngIf="hasError(form.controls.password) !== 'has-error'"
                    class="input-icon"
                    src="../../../../../assets/images/material-icon-lock.svg"/>

                    <img *ngIf="hasError(form.controls.password) === 'has-error'"
                    class="input-icon"
                    src="../../../../../assets/images/material-icon-error.svg"/>

                    <input
                        maxlength="150"
                        aria-label="Confirm the password"
                        class="form-control col-12 input-password"
                        [type]="showConfirmPassword ? 'text' : 'password'"
                        placeholder="{{ 'authentication.confirm-password' | translate }}"
                        formControlName="confirmPassword"
                        [ngClass]="hasError(form.controls.confirmPassword)">

                    <div class="input-group-append copy">
                        <span class="input-group-text">
                            <i class="material-icons" (click)="toggleShowConfirmPassword()">
                                {{ showConfirmPassword ? 'visibility' : 'visibility_off' }}
                            </i>
                         </span>
                    </div>
                </div>
            </div>
            <app-input-error field="confirmPassword" [formGroup]="form"></app-input-error>

            <div class="row mt-7">
                <div class="col-12">
                    <button class="btn btn-submit" type="submit" [disabled]="form.invalid" translate>
                        authentication.change-password.title
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
