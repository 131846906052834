import { NgxSpinnerModule } from 'ngx-spinner';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CaptchaValidator } from './captcha-validator.directive';

@NgModule({
    declarations: [CaptchaValidator],
    imports: [CommonModule, NgxSpinnerModule],
    exports: [CaptchaValidator, NgxSpinnerModule],
})
export class CaptchaValidatorModule {}
