<div class="auth-full container-fluid">
    <div class="nav-actions">
        <app-header-menu></app-header-menu>
        <app-lang-switcher></app-lang-switcher>
    </div>
    <div class="auth absolute-center">
        <header class="header mb-4">
            <app-animated-logotipo [showPlus]="true" [showBlocks]="true"></app-animated-logotipo>

            <h2 class="login-title mt-2 mb-0 text-white" translate>authentication.login.title</h2>
            <h3 class="login-description" translate>authentication.login.description</h3>
            <p class="login-welcome" translate>authentication.login.welcome</p>
        </header>

        <form class="form-login" [formGroup]="form" (ngSubmit)="form.valid && onSubmit()">
            <div class="form-group mb-1 relative">
                <img class="input-icon"
                src="assets/images/material-icon-email.svg"
                alt="icon-email"/>

                <input
                    maxlength="150"
                    id="email"
                    aria-label="Email"
                    class="form-control col-12 input-email"
                    type="email"
                    placeholder="{{ 'authentication.email' | translate }}"
                    formControlName="email"
                    [ngClass]="hasError(form.controls.email)">
            </div>
            <app-input-error field="email"
                                [formGroup]="form"
                                [showEmpty]="false">
            </app-input-error>

            <div class="form-group d-block mt-3 mb-1">
                <div class="d-inline-flex password-group relative">
                    <img *ngIf="hasError(form.controls.password) !== 'has-error'"
                    class="input-icon"
                    src="assets/images/material-icon-lock.svg"
                    alt="icon-lock"/>

                    <img *ngIf="hasError(form.controls.password) === 'has-error'"
                    class="input-icon"
                    src="assets/images/material-icon-error.svg"
                    alt="icon-error"/>

                    <input
                        maxlength="150"
                        id="password"
                        aria-label="Password"
                        class="form-control col-12 input-password"
                        [type]="showPassword ? 'text' : 'password'"
                        placeholder="{{ 'authentication.password' | translate }}"
                        formControlName="password"
                        [ngClass]="hasError(form.controls.password)">

                    <div class="input-group-append copy">
                        <span class="input-group-text">
                            <i class="material-icons" (click)="toggleShowPassword()">
                                {{ showPassword ? 'visibility' : 'visibility_off' }}
                            </i>
                         </span>
                    </div>
                </div>
            </div>
            <app-input-error field="password"
                             [formGroup]="form"
                             [showEmpty]="false">
            </app-input-error>

            <div class="row mt-2">
                <a class="forgot-password col-12 text-right" [routerLink]="['./forgot-password']" translate>
                    authentication.forgot-password.title
                </a>
            </div>

            <div class="mt-2 mt-3" *ngIf="showRecaptcha && isProductionMode">
                <re-captcha formControlName="recaptchaReactive" captchaValidator [btnSubmit]="btnSubmit"></re-captcha>
                <app-input-error field="recaptchaReactive"
                                [formGroup]="form"
                                [showEmpty]="false">
                </app-input-error>
            </div>

            <div class="row btn-login mt-8">
                <div class="col-12 position-relative">
                    <ngx-spinner
                        [zIndex]="1"
                        name="btn-login"
                        bdColor="rgb(0,0,0,0)"
                        color="#fff"
                        size="default"
                        type="ball-clip-rotate"
                        [fullScreen]="false">
                    </ngx-spinner>
                    <button #btnSubmit class="btn btn-submit fade-in" type="submit" translate>
                        authentication.login.title
                    </button>
                </div>
            </div>
        </form>
    </div>

    <app-version-tag class="login-version-tag"></app-version-tag>
    <span (click)="openCookieDialog()" class="cookies-dialog-login" translate>cookies.dialog.title</span>
</div>
