/* src/app/modules/cross-profile/authentication/forgot-password/forgot-password.component.scss */
.forgot-password-title {
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  color: white;
  font-size: 20px;
  letter-spacing: 1px;
}
.forgot-password-description {
  font-size: 12px;
  text-align: center;
  color: white;
}
/*# sourceMappingURL=forgot-password.component-SZN2DLU5.css.map */
