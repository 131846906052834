<finstein-base-modal [disableClose]="true" [title]="currentLabels.title | translate" bodyClass="none">
    <finstein-circle-warn header></finstein-circle-warn>
    <finstein-plain-text-content content [text]="currentLabels.text | translate: { seconds: seconds }"></finstein-plain-text-content>
    <finstein-action-confirm actions
        type="done"
        [doneBtnTitle]="currentLabels.confirmButtonTitle"
        (doneClick)="action()">
    </finstein-action-confirm>
</finstein-base-modal>

