<div class="auth-full container-fluid">
    <div class="password-nav-actions">
        <a class="back-to-login" [routerLink]="['/authentication']">
            <i class="material-icons">keyboard_backspace</i>
            {{ 'authentication.back-to-login' | translate }}
        </a>
        <app-lang-switcher></app-lang-switcher>
    </div>

    <div class="auth absolute-center">
        <header class="header mb-6">
            <app-animated-logotipo></app-animated-logotipo>

            <h2 class="forgot-password-title mt-4 mb-4" translate>authentication.forgot-password.title</h2>
            <h3 class="forgot-password-description" translate>authentication.forgot-password.description</h3>
        </header>

        <form class="form-login" [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="form-group mb-1 relative">
                <img alt="icon-email" class="input-icon" src="../../../../../assets/images/material-icon-email.svg"/>
                <input
                    maxlength="150"
                    aria-label="Email"
                    class="form-control col-12 input-email"
                    type="email"
                    placeholder="{{ 'authentication.email' | translate }}"
                    formControlName="email"
                    [ngClass]="hasError(form.controls.email)">
            </div>
            <app-input-error field="email" [formGroup]="form"></app-input-error>

            <div class="mt-2 mt-3">
                <re-captcha formControlName="recaptchaReactive" captchaValidator [btnSubmit]="btnSubmit"></re-captcha>
            </div>

            <div class="row mt-3">
                <div class="col-12">
                    <button #btnSubmit class="btn btn-submit" type="submit" [disabled]="form.invalid" translate>
                        authentication.forgot-password.submit
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
