/* src/app/modules/cross-profile/authentication/change-password/change-password.component.scss */
.change-password-title {
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  color: white;
  font-size: 20px;
  letter-spacing: 1px;
}
.change-password-description {
  font-size: 12px;
  text-align: center;
  color: white;
}
.password-group {
  display: flex;
  align-items: center;
}
.password-group input {
  padding-right: 42px !important;
}
.input-group-text {
  padding-top: 1px !important;
  padding-bottom: 0px !important;
}
.input-group-append {
  margin-top: 0px !important;
}
:host ::ng-deep mat-card {
  background: rgba(0, 0, 0, 0);
  border: 1px solid #fff;
}
:host ::ng-deep mat-card mat-card-content span {
  font-size: 12px !important;
  color: #fff;
}
:host ::ng-deep mat-card .mat-icon.mat-warn,
:host ::ng-deep mat-card .mat-icon.mat-primary {
  color: #fff;
}
:host ::ng-deep .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #fff;
  --mdc-linear-progress-active-track-color: #fff;
}
:host ::ng-deep .mat-mdc-progress-bar {
  --mdc-linear-progress-track-color: rgba(255, 255, 255, .2);
}
:host ::ng-deep .mdc-linear-progress__bar-inner {
  border-color: #fff;
}
/*# sourceMappingURL=change-password.component-MULGJAHW.css.map */
