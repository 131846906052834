import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { EMPTY, interval, Observable, of, Subscription } from 'rxjs';
import { concatMap, take } from 'rxjs/operators';
import { AUTH_RECOVERY_TIME } from 'src/app/app.constants';

@Component({
    templateUrl: './inactive-user-modal.component.html',
    styleUrls: ['./inactive-user-modal.component.scss'],
})
export class InactiveUserModalComponent implements OnInit, OnDestroy {
    currentLabels;
    private stayLogged = {
        title: 'core.inactive-user-modal.inactive.title',
        text: 'core.inactive-user-modal.inactive.text',
        confirmButtonTitle: 'core.inactive-user-modal.inactive.stay-logged',
    };
    private afterLogout = {
        title: 'core.inactive-user-modal.after-logout.title',
        text: 'core.inactive-user-modal.after-logout.text',
        confirmButtonTitle: 'system.ok',
    };
    private timerSubscription: Subscription;

    seconds: string;
    timer: Observable<number>;

    constructor(
        private dialogRef: MatDialogRef<InactiveUserModalComponent>,
        private router: Router
    ) {
        dialogRef.disableClose = true;
    }

    ngOnInit(): void {
        this.currentLabels = this.stayLogged;
        this.seconds = AUTH_RECOVERY_TIME.toString();

        this.timerSubscription = interval(1000)
            .pipe(
                take(AUTH_RECOVERY_TIME),
                concatMap((seconds) => {
                    this.seconds = (AUTH_RECOVERY_TIME - seconds)
                        .toString()
                        .padStart(2, '0');
                    // the event ends exactly on the 29th
                    if (seconds + 1 === AUTH_RECOVERY_TIME) {
                        this.currentLabels = this.afterLogout;
                        return of(true);
                    }
                    return EMPTY;
                })
            )
            .subscribe(() => {
                this.router.navigate(['/authentication'], { queryParams: { disableRedirect: true, inactiveUser: true }, });
            });
    }

    ngOnDestroy(): void {
        this.timerSubscription.unsubscribe();
    }

    action() {
        this.dialogRef.close(this.timerSubscription.closed);
    }
}
