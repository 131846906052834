import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BaseModalModule } from '../base-modal/base-modal.module';
import { UnsavedChangesModalComponent } from './unsaved-changes-modal.component';

@NgModule({
    imports: [
        CommonModule,
        BaseModalModule
    ],
    declarations: [UnsavedChangesModalComponent],
    exports: [UnsavedChangesModalComponent]
})
export class UnsavedChangesModalModule {
}
