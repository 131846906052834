import { CommonModule } from '@angular/common';
import { environment } from '../../../../environments/environment';
import { AuthFirebaseService } from './auth-firebase.service';
import { canActivate } from './auth-guard.service';
import { LoginComponent } from './login/login.component';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { InputErrorModule } from '../../../core/components/input-error/input-error.module';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { AnimatedLogotipoModule } from '../../../shared/components/animated-logotipo/animated-logotipo.module';
import { LangSwitcherModule } from '../../../shared/components/lang-switcher/lang-switcher.module';
import {
    RECAPTCHA_LANGUAGE,
    RECAPTCHA_SETTINGS,
    RecaptchaFormsModule,
    RecaptchaModule,
} from 'ng-recaptcha';
import { VersionTagModule } from 'src/app/shared/components/version-tag/version-tag.module';
import { UnsavedChangesModalModule } from '../../../core/components/unsaved-changes-modal/unsaved-changes-modal.module';
import { CaptchaValidatorModule } from 'src/app/core/directives/captcha-validator/captcha-validator.module';
import { HeaderMenuModule } from 'src/app/core/components/header-menu/header-menu.module';
import { RouterModule, Routes } from '@angular/router';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularFireModule } from '@angular/fire/compat';
import { LOCALE_ID, NgModule } from '@angular/core';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';

const routes: Routes = [
    {
        path: '',
        component: LoginComponent,
    },
    {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
    },
    {
        path: 'change-password/:key',
        component: ChangePasswordComponent,
    },
];

@NgModule({
    declarations: [
        LoginComponent,
        ForgotPasswordComponent,
        ChangePasswordComponent,
    ],
    imports: [
        CommonModule,
        AngularFireModule.initializeApp(environment.firebaseConfig),
        FormsModule,
        RouterModule.forChild(routes),
        ReactiveFormsModule,
        HttpClientModule,
        TranslateModule,
        InputErrorModule,
        AnimatedLogotipoModule,
        LangSwitcherModule,
        RecaptchaModule,
        RecaptchaFormsModule,
        VersionTagModule,
        UnsavedChangesModalModule,
        CaptchaValidatorModule,
        NgxSpinnerModule,
        HeaderMenuModule,
        MatPasswordStrengthModule
    ],
    providers: [
        AuthFirebaseService,
        {
            provide: RECAPTCHA_SETTINGS,
            useValue: { siteKey: environment.reCaptchaConfigs.siteKey },
        },
        {
            provide: RECAPTCHA_LANGUAGE,
            useFactory: (locale: string) => locale,
            deps: [LOCALE_ID],
        },
    ],
})
export class AuthenticationModule {}
