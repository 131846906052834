import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { BaseFormComponent } from '../../../../core/abstractions/base-form.component';
import { CustomValidators } from '../../../../core/util/custom-validators';
import { AuthFirebaseService } from '../auth-firebase.service';

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: [
        '../login/login.component.scss',
        './change-password.component.scss'
    ]
})
export class ChangePasswordComponent extends BaseFormComponent implements OnInit, OnDestroy {

    user;
    showPassword = false;
    showConfirmPassword = false;
    keySubscription: Subscription;

    constructor(
        public auth: AuthFirebaseService,
        private activatedRoute: ActivatedRoute
    ) {
        super();
    }

    ngOnInit() {
        super.ngOnInit();
        this.activatedRoute.params.subscribe((params) => {
            this.getUserByKey(params.key);
        });
    }

    getFormControls() {
        return {
            password: new UntypedFormControl('', [
                Validators.required,
                CustomValidators.noWhitespaceValidator,
                CustomValidators.isPasswordValid
            ]),
            confirmPassword: new UntypedFormControl('', [
                Validators.required,
                CustomValidators.noWhitespaceValidator,
                CustomValidators.checkPasswordsValidator
            ])
        };
    }

    ngOnDestroy(): void {
        this.keySubscription.unsubscribe();
    }

    onSubmit() {
        if (this.form.valid) {
            this.spinner.show();
            const password = this.form.get('password').value;
            const email = this.user.email.toLowerCase();
            this.auth.changePassword(email, password).subscribe(() => {
                this.spinner.hide();
                this.notification.success('messages.password-updated-succesfully');
                this.navigate(['']);
            }, () => {
                this.spinner.hide();
                this.notification.error('messages.couldnt-update-password');
            });
        } else {
            this.form.markAllAsTouched();
        }
    }

    getUserByKey(key) {
        this.spinner.show();
        this.keySubscription = this.auth.getUserByResetPasswordKey(key)
            .subscribe((data) => {
                if (data) {
                    this.user = data;
                } else {
                    this.navigate(['/authentication/login']);
                    this.notification.error(this.translate('messages.invalid-reset-key'));
                }
                this.spinner.hide();
            }, () => {
                this.notification.error(this.translate('messages.invalid-reset-key'));
                this.navigate(['']);
                this.spinner.hide();
            }, () => {
                this.keySubscription.unsubscribe();
            });
    }

    toggleShowPassword() {
        this.showPassword = !this.showPassword;
    }

    toggleShowConfirmPassword() {
        this.showConfirmPassword = !this.showConfirmPassword;
    }

}
