import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { BaseFormComponent } from '../../../../core/abstractions/base-form.component';
import { CustomValidators } from '../../../../core/util/custom-validators';
import { AuthFirebaseService } from '../auth-firebase.service';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: [
        '../login/login.component.scss',
        './forgot-password.component.scss'
    ]
})
export class ForgotPasswordComponent extends BaseFormComponent implements OnInit {

    constructor(private auth: AuthFirebaseService) {
        super();
    }

    ngOnInit() {
        super.ngOnInit();
    }

    getFormControls() {
        return {
            email: new UntypedFormControl('', [Validators.required, CustomValidators.noWhitespaceValidator, CustomValidators.email]),
            recaptchaReactive: new UntypedFormControl(null, Validators.required)
        };
    }

    onSubmit() {
        if (this.form.valid) {
            this.spinner.show();
            const email = this.form.get('email').value.toLowerCase();
            this.auth.requestResetPassword(email).subscribe(() => {
                this.spinner.hide();
                this.notification.success('An e-email was sent to you containing the link to reset your password.');
                this.navigate(['']);
            }, () => {
                this.spinner.hide();
                this.notification.error('Couldn\'t send the request to reset the password');
            });
        } else {
            this.form.markAllAsTouched();
        }
    }
}
