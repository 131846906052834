import { HttpClient } from '@angular/common/http';
import { Directive, Input } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Functions, httpsCallableData } from '@angular/fire/functions';
import {
    AbstractControl,
    AsyncValidator,
    NG_ASYNC_VALIDATORS,
    ValidationErrors,
    Validators
} from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { RECAPTCHA_VALIDATOR_FUNCTION } from '../../../app.constants';
import { BaseService } from '../../abstractions/base-service';
import { FireLoggingService } from '../../services/fire-logging.service';

@Directive({
    selector: '[captchaValidator][btnSubmit]',
    providers: [
        {
            provide: NG_ASYNC_VALIDATORS,
            useExisting: CaptchaValidator,
            multi: true,
        },
    ],
})
export class CaptchaValidator implements AsyncValidator {
    @Input()
    btnSubmit;

    constructor(
        protected functions: Functions,
        private ngxSpinnerService: NgxSpinnerService,
        protected fireLogging: FireLoggingService
    ) {}

    /**
     * ## Async captcha validator
     * ### Validate that the captcha field is valid
     * `const captcha = new FormControl(undefined, CustomValidators.captcha);`
     *
     * @param control - RECaptcha @see {@link AbstractControl};
     * @returns An error map with captchaInvalid `{ captchaInvalid: true }` or
     *          an error map with the required  - if the validation check fails,
     *          otherwise `null`;
     */
    validate(control: AbstractControl): Observable<ValidationErrors | null> {
        const err = Validators.required(control);
        if (err) {
            return of(err);
        }
        const request = {
            recaptchaSiteKey: environment.reCaptchaConfigs.siteKey,
            token: control.value,
        };
        this.ngxSpinnerService.show('btn-login');
        return httpsCallableData(
            this.functions,
            RECAPTCHA_VALIDATOR_FUNCTION
        )(request).pipe(
            catchError(() => of({ captchaInvalid: true })),
            finalize(() => {
                this.ngxSpinnerService.hide('btn-login');
            })
        );
    }
}
